import type { CrossbrandItem } from '@integration-layer/ampliance/schemas/crossbrand-item-schema.localized'
import type { HomepageBrandCardWrapper } from '@design-system/components/Homepage/HomepageBrandCardWrapper.props'

export const connectComponents = (
  components: Array<
    { _meta: { name?: string; schema?: string } } & Record<string, any>
  >,
  locale: string,
  vse: string | undefined
) => {
  return (
    components?.map(c => {
      const { _meta, ...content } = c
      return {
        // @ts-ignore
        deliveryId: _meta.deliveryId as unknown as string,
        locale,
        vse: vse ?? '',
        name: _meta.schema!,
        content: Object.keys(content).length ? content : undefined,
      }
    }) ?? []
  )
}

export const connectCrossbrandCard = (
  card: CrossbrandItem
): HomepageBrandCardWrapper => {
  return {
    card: {
      brand: card.brand,
      link: card.link,
      mainMedia: {
        type: card.mainMedia?.type,
        src: card.mainMedia?.url?.public_id ?? '',
        alt: card.mainMedia?.alt ?? '',
      },
      hoverMedia: {
        type: card?.hoverMedia?.type,
        src: card.hoverMedia?.url?.public_id ?? '',
        alt: card.hoverMedia?.alt as string,
      },
      bannerLogo: {
        type: card.bannerLogo?.type,
      },
      hoverLogo: card.hoverLogo?.[0]
        ? {
            type: card.hoverLogo[0].type,
            color: card.hoverLogo[0].color,
            overlayBackground: card.hoverLogo[0].overlayBackground,
          }
        : undefined,
      // @ts-ignore
      firstLabel: card.firstLabel,
      secondLabel: card.secondLabel ? card.secondLabel?.[0] : undefined,
    },
  }
}
